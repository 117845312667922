// i18next-extract-mark-ns-start careers

import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Careers: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Careers');
  return (
    <div className="py-24 md:py-32">
      <SEO title={title} />
      <div className="container px-6 py-7 md:py-14 relative z-10 min-h-screen flex justify-center">
        <article className="prose prose-sm sm:prose xl:prose-lg max-w-full">
          <h1>{title}</h1>
          <Trans parent="p">
            Find below our existing open positions. If you’re interested in any of them, please send
            your CV with a detailed cover page for the position you’re applying to{' '}
            <a href="mailto:hi@microapps.com">hi@microapps.com</a>
          </Trans>
          <h2>Senior Back End Developer – Full Time InHouse (Remote also possible)</h2>
          <Trans parent="p">
            We are looking for a person who is not afraid of using new technologies, and who likes
            to solve difficult problems. The person who has experience developing fully tested,
            interactive web applications using best practices. Providing a great web user experience
            is crucial to us, and we’re always looking for talented back-end web developers to help
            us reach the next level.
          </Trans>
          <Trans parent="h3">Responsibilities:</Trans>
          <ul>
            <Trans parent="li">
              Implement the next generation backend systems using latest technologies, specially
              Serverless with deep usage of AWS Lambda and the whole AWS set of products.
            </Trans>
            <Trans parent="li">Write well organised and sustainable JavaScript code</Trans>
            <Trans parent="li">Follow best practices for agile software development</Trans>
          </ul>
          <Trans parent="h3">Requirements:</Trans>
          <ul>
            <Trans parent="li">5+ years experience as a Back End Engineer</Trans>
            <Trans parent="li">Exceptional at object-oriented JavaScript</Trans>
            <Trans parent="li">Node.js experience</Trans>
            <Trans parent="li">Knowledge of ES2015</Trans>
            <Trans parent="li">Knowledge of Unit Testing and Test Driven Development</Trans>
            <Trans parent="li">Experience writing code that interacts with RESTful APIs</Trans>
            <Trans parent="li">Experience with version control, Gitflow</Trans>
            <Trans parent="li">Must have excellent verbal and communication skills</Trans>
            <Trans parent="li">Must be self-sufficient; able to work independently</Trans>
            <Trans parent="li">Strong collaboration skills</Trans>
          </ul>
          <Trans parent="h3">Benefits:</Trans>
          <ul>
            <Trans parent="li">Competitive base salary</Trans>
            <Trans parent="li">Share of profits</Trans>
            <Trans parent="li">A highly motivated and talented team</Trans>
            <Trans parent="li">
              A beach 1 minute from work. Yes! We’re in front of the beach!!
            </Trans>
            <Trans parent="li">
              Flat organizational structure that ensures your influence on products we build
            </Trans>
          </ul>
          <Trans parent="p">
            Candidate will be working either in
            <a href="https://monei.net/" target="_blank" rel="nofollow noreferrer">
              MONEI
            </a>{' '}
            or{' '}
            <a href="https://moonmail.io/" target="_blank" rel="nofollow noreferrer">
              MoonMail
            </a>{' '}
            projects.
          </Trans>
          <h2>Growth Hacker – MoonMail – Full Time InHouse (Remote also possible)</h2>
          <Trans parent="p">We’re looking for a growth hacker to:</Trans>
          <ul>
            <Trans parent="li">
              quadruple our revenue from $10k to $40k per month over the next year
            </Trans>
            <Trans parent="li">
              put in place a system for measuring, reporting, and optimizing key user growth metrics
            </Trans>
            <Trans parent="li">
              use growth hacking techniques to improve user engagement and retention
            </Trans>
          </ul>
          <Trans parent="h3">Requirements:</Trans>
          <Trans parent="p">
            <strong>Be a Geek</strong>. We’re not looking for a marketer. We’re looking for someone
            who really knows about the Internet at its broadest sense. GitHub and StackOverflow are
            for developers. Yes. But you should know a bit more than it’s url. If you don’t. This is
            not for you.
          </Trans>
          <Trans parent="h3">Benefits:</Trans>
          <ul>
            <Trans parent="li">Competitive base salary</Trans>
            <Trans parent="li">Share of profits</Trans>
            <Trans parent="li">Free access to sports facilities</Trans>
            <Trans parent="li">Create your own workstation with any type of equipment</Trans>
            <Trans parent="li">
              A beach 1 minute from work. Yes! We’re in front of the beach!!
            </Trans>
            <Trans parent="li">A highly motivated and talented team</Trans>
            <Trans parent="li">Snacks and drinks in the office</Trans>
            <Trans parent="li">
              Flat organizational structure that ensures your influence on products we build
            </Trans>
            <Trans parent="li">Flexible timetable.</Trans>
          </ul>
          <h2>Senior Front End Developer – Full Time Remote</h2>
          <Trans parent="p">
            We are looking for a person who is not afraid of using new technologies, and who likes
            to solve difficult problems. The person who has experience developing fully tested,
            interactive web applications using best practices. Providing a great web user experience
            is crucial to us, and we’re always looking for talented front-end web developers to help
            us reach the next level.
          </Trans>
          <Trans parent="h3">Responsibilities:</Trans>
          <ul>
            <Trans parent="li">
              Implement the next generation user interfaces using latest technologies and UX
              practices
            </Trans>
            <Trans parent="li">Write well organised and sustainable JavasScript code</Trans>
            <Trans parent="li">Follow best practices for agile software development</Trans>
          </ul>
          <Trans parent="h3">Requirements:</Trans>
          <ul>
            <Trans parent="li">5+ years experience as a Front End Engineer</Trans>
            <Trans parent="li">Exceptional at object-oriented JavaScript</Trans>
            <Trans parent="li">
              Experience with multiple frameworks, and libraries like Angular, React, Backbone,
              Gulp, Jasmine, PhantomJS, Protractor, JQuery, Underscore
            </Trans>
            <Trans parent="li">Knowledge of Unit Testing and Test Driven Development</Trans>
            <Trans parent="li">
              Experience writing code that interacts with a large, complex backend through RESTful
              APIs
            </Trans>
            <Trans parent="li">Strong experience in CSS3: Sass (or Less), animations</Trans>
            <Trans parent="li">Experience with version control, Gitflow</Trans>
            <Trans parent="li">Must have excellent verbal and communication skills</Trans>
            <Trans parent="li">Must be self-sufficient; able to work independently</Trans>
            <Trans parent="li">Strong collaboration skills</Trans>
            <Trans parent="li">
              Experience implementing complex responsive modern user interfaces
            </Trans>
            <Trans parent="li">Node.js experience</Trans>
            <Trans parent="li">Knowledge of ES2015</Trans>
            <Trans parent="li">Extensive experience with React</Trans>
          </ul>
          <Trans parent="h3">Benefits:</Trans>
          <ul>
            <Trans parent="li">Competitive base salary</Trans>
            <Trans parent="li">Share of profits</Trans>
            <Trans parent="li">Free access to sports facilities</Trans>
            <Trans parent="li">A highly motivated and talented team</Trans>
            <Trans parent="li">
              A beach 1 minute from work. Yes! We’re in front of the beach!!
            </Trans>
            <Trans parent="li">
              Flat organizational structure that ensures your influence on products we build
            </Trans>
            <Trans parent="li">Flexible timetable.</Trans>
          </ul>
          <Trans parent="p">
            Candidate will be working either in
            <a href="https://monei.net/" target="_blank" rel="nofollow noreferrer">
              MONEI
            </a>{' '}
            or{' '}
            <a href="https://moonmail.io/" target="_blank" rel="nofollow noreferrer">
              MoonMail
            </a>{' '}
            projects.
          </Trans>
        </article>
      </div>
    </div>
  );
};

export default Careers;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "careers"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
